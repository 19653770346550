/* The switch - the box around the slider */
.switchC {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

/* Hide default HTML checkbox */
.switchC input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.sliderC {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.sliderC:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .sliderC {
  background-color: #0ea800;
}

input:focus + .sliderC {
  box-shadow: 0 0 0px #0ea800;
}

input:checked + .sliderC:before {
  -webkit-transform: translateX(18px);
  -ms-transform: translateX(18px);
  transform: translateX(18px);
}

/* Rounded sliders */
.sliderC.roundC {
  border-radius: 16px;
}

.sliderC.roundC:before {
  border-radius: 40%;
}