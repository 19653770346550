table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

table thead th {
  border-top: none !important;
  border-bottom: none !important;
  box-shadow: inset 0 1px 0 #ddd, inset 0 -1px 0 #ddd;
}

table td {
  border: 1px solid #ddd;
  padding: 4px;
  padding-block: 8px;
}

table th {
  border: 1px solid #d4d4d4;
  padding: 4px;
  padding-block: 8px;
}

table tr:nth-child(even){background-color: #f2f2f2;}
table tr:nth-child(odd){background-color: #ffffff;}

table tr:hover {background-color: #f7f9e5;}

.overview th, .overview tfoot td {
  padding-top: 24px;
  padding-bottom: 16px;
  text-align: center;
  background-color: #075985;
  color: white;
}

.overview td:nth-child(3) { 
  border-left:2px solid #d4d4d4;
}

